import React from "react";
import styled from '@emotion/styled';
import Obfuscate from 'react-obfuscate';

import SEO from "../components/seo"
import PageTitle from '../components/PageTitle';

// import data from '../../content/data/about/support.yml';

const SupportPage = () => (
  <>
    <SEO title="Support" />
    <CENTER>
      <PageTitle title="Support"/>
      <P>Please email &nbsp;
        <Obfuscate email="support@tonybronzo.com" /> for questions and account support.
      </P>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </CENTER>
  </>
)

const CENTER = styled('main')`
  width: 100vw;
  margin: 0 auto;
  background: #FFF;

	// image switches to fullscreen
	@media (max-width: 500px) {
		width: 100%;
  }
`;

const P = styled('p')`
    font-size: 20px;
    width: 80%;
    text-align: center;
    padding-bottom: 80px;
    margin: 0 auto;
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;

    @media (max-width: 500px) {
      padding-left: 50px;
      padding-right: 50px;
      font-size: 15px !important;
    }
`;

export default SupportPage;
